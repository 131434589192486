import Alpine from "alpinejs";

var usng = require('usng.js');

var converter = new usng.Converter();

document.addEventListener('alpine:init', () => {
    Alpine.data('mgrsCoordinatesType', ($el) => ({
        mgrs1: $el.querySelector('[x-model="mgrs1"]').value,
        mgrs2: $el.querySelector('[x-model="mgrs2"]').value,
        mgrs3: $el.querySelector('[x-model="mgrs3"]').value,
        mgrs4: $el.querySelector('[x-model="mgrs4"]').value,

        container: {
            '@getCurrentLocation.window'() {
                this.extractGpsCoordinates();
            },
        },

        extractGpsCoordinates: function() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.latitude = position.coords.latitude;
                    this.longitude = position.coords.longitude;
                    var usngCoord = converter.LLtoUSNG(position.coords.latitude, position.coords.longitude, 100000);
                    const coords = usngCoord.split(' ');
                    this.mgrs1 = coords[0];
                    this.mgrs2 = coords[1];
                    this.mgrs3 = coords[2];
                    this.mgrs4 = coords[3];

                });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        },
    }))
})
