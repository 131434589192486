import Alpine from "alpinejs";

document.addEventListener('alpine:init', () => {
    Alpine.data('gallery', ($el) => ({
        isOpen: false,
        isLoading: false,
        src: 'about:blank',
        desc: '',

        content: {
            'x-show'() { return this.isOpen },
            ':aria-hidden'() { return !this.isOpen },
            'x-on:click.outside'() { this.close() },
            'x-transition:enter'() { return 'ease-out duration-300' },
            'x-transition:enter-start'() { return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' },
            'x-transition:enter-end'() { return 'opacity-100 translate-y-0 sm:scale-100' },
            'x-transition:leave'() { return 'ease-in duration-200' },
            'x-transition:leave-start'() { return 'opacity-100 translate-y-0 sm:scale-100' },
            'x-transition:leave-end'() { return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' },
        },

        container: {
            '@open-gallery.window'($event) {
                if ($event.detail.url) {
                    this.open($event.detail.url);
                }
            },
            '@close-modal.window'($event) {
                this.close();
            },
            'x-on:click'() { this.close() },
            'x-show'() { return this.isOpen },
            ':aria-hidden'() { return !this.isOpen },
            'x-on:keydown.escape.window'() { this.close() },
        },

        image: {
            'x-show'() { return !this.isLoading },
            'x-on:load'() { this.isLoading = false},
            ':alt'() { return this.src },
            ':src'() { return this.src },
        },

        open(src) {
            this.isLoading = true;
            this.src = src;
            this.isOpen = true;
        },

        close() {
            if (this.isOpen) {
                this.src = 'about:blank';
                this.isOpen = false;
                this.isLoading = false;
            }
        },
    }))
})



