import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

Alpine.plugin(focus)

window.Alpine = Alpine;

import './alpine/x-data/dropdown';
import './alpine/x-data/gallery';
import './alpine/x-data/gps-coordinates-type';
import './alpine/x-data/mgrs-coordinates-type';
import './alpine/x-data/modal';
import './alpine/x-data/slideover';
import './alpine/x-data/tailwind-choice-type';

Alpine.start();

