/**
 * Installing this package requires you to have access to the private package, part of a paid FortAwesome subscription.
 *
 * If you need the token, please talk to Jan Klan <jan@itools.net.au>
 */
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/solid.scss';
import '@fortawesome/fontawesome-pro/scss/brands.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';

import './alpine';
// import './collection';

// To support smooth scrolling in Safari: https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('turbo:submit-start', (event) => {
    if (event.target && event.target.querySelector) {

        // Turn off all submit buttons when the form has been submitted
        event.target.querySelectorAll('button').forEach(btn => {
            btn.disabled = true;
        })

        const indicator = event.target.querySelector('.loading-indicator');
        if (indicator) {
            indicator.classList.remove('hidden');
        }
    }
})

document.addEventListener('turbo:submit-end', (event) => {
    if (event.target && event.target.querySelector) {

        // Turn the buttons back on (even though they might be replaced with a fresh HTML shortly - doesn't matter.)
        event.target.querySelectorAll('button').forEach(btn => {
            btn.disabled = false;
        })

        const indicator = event.target.querySelector('.loading-indicator');
        if (indicator) {
            indicator.classList.add('hidden');
        }
    }
})

// start the Stimulus application
import './bootstrap';

document.addEventListener("turbo:load", function() {
    var lazyloadImages;

    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(".lazy");
        var imageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    image.src = image.dataset.src;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function(image) {
            imageObserver.observe(image);
        });
    } else {
        var lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(".lazy");

        function lazyload () {
            if(lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function() {
                var scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function(img) {
                    if(img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                    }
                });
                if(lazyloadImages.length === 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }
})

const copyToClipboard = (str, iconEl, iconToMute) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    if (iconEl && iconToMute) {
        iconEl.classList.replace(iconToMute, 'fa-check');
        setTimeout(() => {
            iconEl.classList.replace('fa-check', iconToMute);
        }, 1000);
    }
    return true;
};

global.copyToClipboard = copyToClipboard;

// Experimental Web Component for Tooltips
class TooltipComponent extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
            <button type="button" class="group relative z-50">
                <i class="fal fa-question-circle fa-fw"></i>
                <span class="hidden group-hover:block">
                    <span class="w-4 overflow-hidden inline-block absolute top-full -right-0.5">
                        <span class="h-2 w-2 bg-gray-800 block rotate-45 transform origin-bottom-left"></span>
                    </span>
                    <span class="mt-2 p-2 bg-gray-800 rounded shadow opacity-90 block absolute origin-bottom-right right-0 top-full text-white min-w-full text-left text-md z-90 w-48">
                        ${this.innerHTML}
                    </span>
                </span>
            </button>`
        ;
    }
}

customElements.define('itools-tooltip', TooltipComponent);
