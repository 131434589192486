import Alpine from "alpinejs";

document.addEventListener('alpine:init', () => {
    Alpine.data('tailwindChoiceType', (element, multiple) => ({
        isOpen: false,
        isSearching: false,

        choices: [],
        matchingChoices: [],
        search: null,
        selectedChoices: [],

        searchEnabled: false,

        label: null,

        init () {

            this.label = this.$refs.label.dataset.currentLabel;

            element.querySelectorAll('input:checked').forEach(input => {
                if (multiple) {
                    this.selectedChoices.push(input.value);
                } else {
                    this.selectedChoices = input.value;
                }
            });

            // Scan all labels to work out which choices are available so we can search them later
            element.querySelectorAll('label[data-choice-value]').forEach(label => {
                this.choices.push({value: label.dataset.choiceValue, label: label.innerText, labelLowercase: label.innerText.toLowerCase()});
            });

            // Decide if we need search or not
            this.searchEnabled = this.choices.length > 5;

            // The search input will update this.search with whatever the user types in. We'll want to watch for those changes
            this.$watch('search', (searchPhrase) => {

                // Hitting that X button, or removing all text, cancels the search
                if (!searchPhrase) {
                    this.matchingChoices = [];
                    this.isSearching = false;
                    return;
                }

                // The search is case insensitive
                searchPhrase = searchPhrase.toLowerCase();

                // As there could be a lot of choices watching for changes, we'll calculate the list of matching
                // choices first, and then update the reactive property once
                const currentMatches = [];
                this.choices.forEach(choice => {
                    if (searchPhrase === choice.value || choice.labelLowercase.includes(searchPhrase)) {
                        currentMatches.push(choice.value);
                    }
                })

                // This will trigger updating the list of displayed choices
                this.matchingChoices = currentMatches;

                this.isSearching = true;
            });

            this.$watch('selectedChoices', (newValue) => {
                if (!multiple) {
                    const selectedChoice = this.choices.find(choice => choice.value === newValue);

                    if (selectedChoice) {
                        this.label = selectedChoice.label;
                    } else {
                        this.label = this.$refs.label.dataset.emptyLabel;
                    }

                    this.$refs.submit.form.submit();

                    this.close();
                }
            });
        },

        open () {
            if (!this.isOpen) {
                this.search = null;
                this.isOpen = true;

                // Not using $nextTick prevents the focus() from working
                this.$nextTick(() => {
                    // The user could have scrolled down previously, and then reopened the dropdown - this resets the initial state
                    this.$refs.choices.scrollTop = 0;

                    if (this.searchEnabled) {
                        // Simplifies the keyboard navigation - the user already expressed the intention to interact with
                        // the element, so let's just let them click once and start typing
                        this.$refs.searchField.focus();
                    }
                });
            }
        },

        close (reset) {
            if (this.isOpen) {
                this.isOpen = false;

                if (reset) {
                    // this.$refs.submit.form.reset();
                } else {
                    // this.$refs.submit.form.requestSubmit();
                }
            }
        },

        showChoice (choice) {
            return !this.search || this.matchingChoices.includes(choice);
        },

        showNoResultsFound() {
            return this.isSearching && this.matchingChoices.length === 0;
        },

        container: {
            'x-on:keydown.escape'() { this.close(true) },
            'x-on:click.outside'() { this.close(false) },
        },

        trigger: {
            'x-on:click'() { this.isOpen ? this.close() : this.open() },
            ':aria-expanded'() { return this.isOpen },
        },

        menu: {
            'x-show'() { return this.isOpen },
            'x-trap'() { return this.isOpen },
            'x-transition:enter'() { return 'transition ease-out duration-100' },
            'x-transition:enter-start'() { return 'transform opacity-0 scale-95' },
            'x-transition:enter-end'() { return 'transform opacity-100 scale-100' },
            'x-transition:leave'() { return 'transition ease-in duration-75' },
            'x-transition:leave-start'() { return 'transform opacity-100 scale-100' },
            'x-transition:leave-end'() { return 'transform opacity-0 scale-95' },
        },

        /**
         * The Badge shows the number of selected items if multiple choice is allowed. It's inserted between the label
         *  and the down arrow
         */
        badge: {
            'x-show'() {
                return multiple && this.selectedChoices.length;
            },
            'x-html'() {
                return this.selectedChoices.length;
            },
        },

        confirmButton: {
            'x-on:click'(ev) {
                this.$refs.submit.form.submit();
            }
        },

        clearButton: {
            'x-on:click'(event) {

                if (this.selectedChoices.length) {
                    this.selectedChoices = [];
                } else {
                    this.close();
                }
            }
        },

        cancelSearchButton: {
            'x-show'() { return this.search },
            'x-on:click'() {
                if (this.searchEnabled) {
                    this.search = null;
                    this.$refs.searchField.focus();
                }
            }
        },
    }))
})
